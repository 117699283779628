<!-- 基本信息 -->
<template>
    <div class="views-BasicInfo">
        <div class="views-title-small">{{$t("userPage.text276")}}</div>
        <Descriptions title="" :column="1">
            <DescriptionsItem :label='$t("userPage.text276")'>
                <div class="BasicInfo-text">**************</div>
                <div class="BasicInfo-btn cursorPointer" @click="_jump('password')">{{$t("userPage.text280")}}</div>
            </DescriptionsItem>
            <DescriptionsItem :label='$t("userPage.text277")'>
                <div class="BasicInfo-text">{{ userData.withdrawPassWordState ? '**************' : ''}}</div>
                <div class="BasicInfo-btn cursorPointer" @click="_jump('fundPass')">{{ userData.withdrawPassWordState ? $t("userPage.text280") : $t("userPage.text281") }}</div>
            </DescriptionsItem>
            <DescriptionsItem :label='$t("userPage.text278")'>
                <div class="BasicInfo-text"></div>
                <div class="BasicInfo-btn cursorPointer" @click="_jump('devices')">{{$t("userPage.text279")}}</div>
            </DescriptionsItem>
        </Descriptions>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { _jump } from '@/core/utils/utils'
import {  Descriptions, DescriptionsItem } from 'element-ui'
export default {
    components: {
        Descriptions,
        DescriptionsItem,
    },
    data () {
        return {
            
        }
    },
    computed: {
        percentage () { // 绑定进度
            let num = 0
            this.userData.phone && (num += 25)
            this.userData.email && (num += 25)
            this.userData.withdrawPassWordState && (num += 25)
            this.userBankList.length && (num += 25)
            return num
        },
        balance () { // 主账户数据
            return this.userData.totalBalance || '0.00'
        },
        ...mapGetters([
            'userData',
            'userPlatformBalance',
            'userBankList',
        ])
    },
    created () {
    },
    methods: {
        _jump
    }
}
</script>

<style lang="scss">
.views-BasicInfo{
    margin-top: 40px;
    .views-title-small{
        color: #202939;
        font-size: 20px;
        font-weight: bold;
        width: 890px;
        margin: 20px auto;
    }
    .el-descriptions{
        color: #6f6f6f;
        font-size: 16px;
        .el-descriptions__body{
            background:transparent;
            .el-descriptions-row{
                .el-descriptions-item__container,.el-descriptions-item__content{
                    display: flex;
                    justify-content:flex-start;
                    align-items: center;
                    height: 50px;
                    flex: 1;
                    .BasicInfo-text{
                        width: 50%;
                        text-align: center;
                    }
                    .BasicInfo-btn{
                        width: 140px;
                        height: 37px;
                        color: #fff;
                        line-height: 37px;
                        font-size: 18px;
                        font-weight: 700;
                        text-align: center;
                        border-radius: 8px;
                        margin-left: auto;
                        background:#ce06cd;
                    }
                }
                .el-descriptions-item__label{
                    width: 200px;
                    display: flex;
                    justify-content:flex-start;
                    line-height: 50px;
                    font-weight: 700;
                }
            }
        }
    }
}

</style>