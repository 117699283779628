<!-- 个人资料页 -->
<template>
    <div class="viewsPages">
        <div class="UserInfo-title">User Information</div>
        <div class="views-UserInfo">
            <!-- 完善资料 -->
            <EditInfo />
            <!-- 基本信息 -->
            <BasicInfo  />
            <!-- 收款方式 -->
            </div>
    </div>
</template>

<script>

import BasicInfo from './BasicInfo.vue'
import EditInfo from './EditInfo.vue'

export default {
    components: {
        BasicInfo,
        EditInfo
    },
    data () {
        return {
            activeIndex: 0,
        }
    },
    methods: {
        
        // 切换导航
        tab (num) {
            if (this.activeIndex == num) return
            this.activeIndex = num
        }
    }
}
</script>

<style scoped>
.views-UserInfo{
    width: 890px;
    margin: 20px auto;
    padding: 10px 40px;
    border-radius: 10px;
    background:#DEDEDE;
}
</style>